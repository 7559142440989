import dynamic from "next/dynamic";
import { Fragment} from "react";


const ConditionRow = dynamic(() => import("./conditionrow"));

const ConditionString = ({ loopConditon }, props) => {
  return (
    <div className=" text-[16px] leading-7 flex flex-wrap">
      <p>
        {Object.keys(loopConditon["rows"]).map(function (rowKey, rowIndex) {
          return (
            <Fragment key={`conditions${rowIndex}`}>
              <ConditionRow
                loopData={loopConditon["rows"][rowIndex]}
                key={`rows ${rowIndex}`}
              ></ConditionRow>
              {loopConditon["rows"].length != rowIndex + 1 ? (
                <span className="font-bold">AND&nbsp;</span>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </p>
    </div>
  );
};

export default ConditionString;
